import { Injectable } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { LocalStorageService } from 'src/app/Services/auth-service/local-storage.service';
import { HomeService } from '../../Home-Modules/home/home.service';
import { ENDPOINTS, ORG_DETAILS } from 'src/app/app.config';
import { config } from "src/configs/config";
import { AlertsService } from '../../../Components/alerts/alerts.service';
import { coerceStringArray } from '@angular/cdk/coercion';

@Injectable({
  providedIn: 'root'
})
export class CartService {

  routeurl;
  options = {
    autoClose: true,
    keepAfterRouteChange: false
  };
  cartItems;
  CONFIG = config;

  constructor(
    private localService: LocalStorageService,
    private appService: AppService,
    private homeService: HomeService,
    private alertService: AlertsService,
  ) { }

  getURL(url) {
    this.routeurl = url;
    console.log("this.routeurlllllllllll" + this.routeurl)
  }


  addToCart(product: any, bestGiftsData?) {
    var quantity;
    console.log(product)
    if (ORG_DETAILS.NAME === 'ShubhCards' || ORG_DETAILS.NAME === 'LinenRaj' || ORG_DETAILS.NAME === 'Karupattiraja' || ORG_DETAILS.NAME === 'Vistas' || ORG_DETAILS.NAME === 'Vimpro' || ORG_DETAILS.NAME === 'SpecialWires') {
      var moq;
      if (product.pricing.raw.minimumorderquantity == null || product.pricing.raw.minimumorderquantity == 0) {
        moq = 1;
      }
      else {
        moq = product.pricing.raw.minimumorderquantity;
      }
      quantity = moq;
    } else if (ORG_DETAILS.NAME == "BestGifts" && bestGiftsData) {
      quantity = bestGiftsData.quantity
    } else {
      quantity = 1;
    }
    let produstData = {
      product_id: product.id,
      product_name: product.name,
      product_subname: product.subname,
      sku: product.sku,
      brand: product.brand.name,
      quantity: quantity,
      cost: product.pricing.raw.price,
      sub_total: product.pricing.raw.price,
      category: "",
      category_id: "",
      subcart: product.subcart,
      price: product.price,
      subquantity: product.quantity,
      size: product.size
    }

    if (ORG_DETAILS.NAME == "BestGifts" && bestGiftsData) {
      produstData["uploaded_images"] = bestGiftsData["uploaded_images"]
      produstData["product"] = product
    } else {
      produstData["product"] = product
    }

    if (product.categories.length > 0) {
      produstData.category = product.categories[0].categories;
      produstData.category_id = product.categories[0].id;
    }
    else {
      produstData.category = product.parentcategories[0].name;
    }

    if (product.assets.length > 0) {
      produstData["image_source"] = product.assets[0].image_source;
    }
    if (this.localService.get("cart")) {
      let cartItems = JSON.parse(this.localService.get("cart"));
      let productInCart = false;
      cartItems.forEach(element => {
        if (element.product_id === produstData.product_id) {
          productInCart = true;
        }
      });
      if (!productInCart) {
        cartItems.push(produstData);
      }

      let cartItemsString = JSON.stringify(cartItems);
      this.localService.set("cart", cartItemsString);
    }
    else {
      this.localService.set("cart", JSON.stringify([produstData]));
    }
    // if(ORG_DETAILS.NAME!=='SpecialWires'){
    this.appService.cartProducts.results = JSON.parse(this.localService.get('cart'));
    // }
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    console.log("111")
    this.updateCartDetails();
    console.log("Cart updated!")
  }

  addToCartSub(product: any) {
    console.log("product", product)
    var quantity;
    quantity = 1;
    let produstData = {
      product_id: product.id,
      product_name: product.name,
      sku: product.sku,
      brand: product.brand.name,
      quantity: product.subquantity.reduce((partialSum, a) => partialSum + a, 0),
      cost: product.pricing.raw.price,
      sub_total: product.pricing.raw.price,
      category: "",
      category_id: "",
      subcart: product.subcart,
      subquantity: product.subquantity,
      price: product.price,
      options: product.size
    }
    if (product.categories.length > 0) {
      produstData.category = product.categories[0].categories;
      produstData.category_id = product.categories[0].id;
    }
    else {
      produstData.category = product.parentcategories[0].name;
    }
    if (product.assets.length > 0) {
      produstData["image_source"] = product.assets[0].image_source;
    }
    if (this.localService.get("cart")) {
      let cartItems = JSON.parse(this.localService.get("cart"));
      let productInCart = false;
      cartItems.forEach(element => {
        if (element.product_id === produstData.product_id) {
          productInCart = true;
          element.subcart = produstData.subcart;
          element.price = produstData.price;
          element.subquantity = produstData.subquantity;
        }
      });
      // console.log(cartItems,"JIJJI")
      if (!productInCart) {
        cartItems.push(produstData);
      }
      else {
        // console.log(cartItems,"test")
      }
      // console.log(cartItems,"JIJJI")

      let cartItemsString = JSON.stringify(cartItems);
      this.localService.set("cart", cartItemsString);
      // console.log(JSON.parse(this.localService.get("cart")),"CART")
    }
    else {
      this.localService.set("cart", JSON.stringify([produstData]));
    }
    console.log("dataaaaaaaaaaaa" + JSON.stringify(this.localService.get('cart')))
    this.appService.cartProducts.results = JSON.parse(this.localService.get('cart'));
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    console.log("222")
    this.updateCartDetails();
    console.log("Cart updated!")
  }

  removeFromCart(product_id: any) {
    let cartItems = JSON.parse(this.localService.get("cart"));
    let tempCartItems = cartItems;
    cartItems.forEach(function (element, index) {
      if (element.product_id === product_id) {
        tempCartItems.splice(index, 1);
      }
    });
    this.localService.set("cart", JSON.stringify(tempCartItems));
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    console.log("333")
    this.updateCartDetails();
  }

  updateCartItem(cart: any) {

    // if(this.routeurl==='/' && ORG_DETAILS.NAME==='Deera'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='BestGifts'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='Karupattiraja'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='Vimpro'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='SpecialWires'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='BombayHardware'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='Beaubelle'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='Aiema'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='Tams'){

    // } else if(this.routeurl==='/' && ORG_DETAILS.NAME==='Haven'){

    // }
    //  else 
    //  {
    console.log("comein3", JSON.stringify(cart));
    this.localService.set("cart", JSON.stringify(cart));
    this.appService.totalCartItems = this.totalCartItems();
    //THIS IS LIKE REASSIGN BCZ IN CHECKOUT PAGA ALSO USING SAME FUNCTION
    this.appService.cartProducts.results = cart;
    this.homeService.updateAdminFormEvent('cartUpdate');
    this.appService.totalCartItems = this.totalCartItems();
    this.calculateSubTotal();
    console.log("444")
    this.updateCartDetails();
    // }

  }

  totalCartItems() {
    let totalItems = 0
    if (this.localService.get("cart")) {

      if (this.localService.get('cart_icon_option')) {

        if (this.localService.get('cart_icon_option') === "Show Total Quantity") {
          let cartItems = JSON.parse(this.localService.get("cart"));
          cartItems.forEach(element => {
            totalItems = totalItems + element.quantity;
          });
        }
        else if (ORG_DETAILS.NAME === 'BombayHardware') {
          // totalItems = this.appService.cartProducts.results.length;
          for (let i = 0; i < this.appService.cartProducts.results.length; i++) {
            totalItems = totalItems + this.appService.cartProducts.results[i].subcart.length;
          }
        }
        else {

          totalItems = this.appService.cartProducts.results.length;
        }
      }
      else {
        totalItems = this.appService.cartProducts.results.length;
      }
    }


    return totalItems;
  }

  clearCart() {
    this.localService.remove("cart");
    this.appService.totalCartItems = 0;
    this.calculateSubTotal();
  }

  calculateSubTotal() {
    let subTotal = 0;
    if (this.localService.get("cart")) {
      let cartItems = JSON.parse(this.localService.get("cart"));
      cartItems.forEach(element => {

        subTotal = subTotal + Number(element.cost) * Number(element.quantity);

      });
    }
    this.appService.cartSubTotal = subTotal;
  }

  //UPDATE CART IN DATABASE
  updateCartDetails() {
    if (this.localService.get('cart') !== null && this.localService.get('session_id') !== null) {

      let cartItems = JSON.parse(this.localService.get("cart"));
      let session_id = this.localService.get("session_id");

      if (session_id !== "") {
        let sub_total = 0
        let total_quantity = 0;

        cartItems.forEach(element => {
          sub_total += Number(element.quantity) * Number(element.cost)
          total_quantity += Number(element.quantity)
        });

        let cart_data = { products: cartItems, session_id: session_id, status: 'active', total_quantity: total_quantity, total_items: cartItems.length, sub_total: sub_total, userId: "" }

        if (this.localService.get("UserId")) {

          cart_data.userId = this.localService.get("UserId");

        }

        this.appService.productIdinCart = [];
        this.appService.cartProducts.results.forEach((element) => {
          this.appService.productIdinCart.push(element.product_id);
        })
        console.log("this.appserviceeeeeeeeeee" + this.appService.cartProducts.results.length)
        if (ORG_DETAILS.NAME === 'BombayHardware') {
          this.appService.subProductinCart = [];
          this.appService.cartProducts.results.forEach((element) => {
            for (let i = 0; i < element.subcart.length; i++) {
              if (!this.appService.subProductinCart.includes(element.subcart[i])) {
                this.appService.subProductinCart.push(element.subcart[i]);
              }
            }
          })
        }
        let formDataJson = {
          domain_name: ORG_DETAILS.DOMAIN_NAME,
          user_id: ORG_DETAILS.USER_ID,
          payload: { cart_creation: cart_data }
        }
        // this.appService.sendCartDetails(formDataJson).subscribe(resp=>
        //   {

        //   },err=>
        //   {

        //   })
        if (this.appService.cartProducts.results.length > 0) {
          this.appService.postApiCall(formDataJson, ENDPOINTS.CART_KAFKA_UPSERT).subscribe(
            resp => {
              if (resp) {
                if (resp.success === 1 && resp.status_code === 200) {
                  // console.log("resp.result.data.id"+resp.result.data[0].id)
                  // this.localService.set("cartId", resp.result.data[0].id);
                }
                else if (resp.success === 0) {
                  if (resp.message) {
                    this.alertService.error(resp.message, this.options);
                  }
                }
                else if (resp.message && resp.status_code !== 200) {
                  this.alertService.error(resp.message, this.options);

                } else {
                  this.alertService.error('Something bad happened. Please try again!', this.options);
                }
              }
            }
          )
        }

      }
    }
  }

}
